// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceWorker: true,
  // apiBaseUrl: 'http://192.168.100.178:8000/',
  //apiBaseUrl: 'http://127.0.0.1:8900/',
  // apiBaseUrl: 'http://192.168.101.250:8900/',

  // apiBaseUrl: 'https://4314-112-196-50-74.ngrok-free.app/',
  apiBaseUrl: 'https://escl.code-brew.com/',
  versionCheckURL: '1.2',
  firebaseConfig: {
    apiKey: 'AIzaSyCbZNmcYOvYj-bL7URj-6mAPtTbfsVOvMU',
    authDomain: 'erpcbl.firebaseapp.com',
    databaseURL: 'https://erpcbl.firebaseio.com',
    projectId: 'erpcbl',
    storageBucket: '',
    messagingSenderId: '145170940175',
    appId: '1:145170940175:web:8c831baa6397408f'
  },
  leaderBoardUrl: 'https://escl.code-brew.com/leaderboard/dev',
  socketBaseUrl: 'https://erpsockets.code-brew.com'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.