import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CSMGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate() {
    const token = localStorage.getItem('api_token');
    if (
      token &&
      localStorage.getItem('desigination') &&
      localStorage.getItem('desigination') === 'CSM'
    ) {
      return true;
    }
    if (
      token &&
      localStorage.getItem('desigination') &&
      localStorage.getItem('desigination') !== 'CSM'
    ) {
      return false;
    }
    this.router.navigate(['']);
    return false;
  }

}
