export const AdminApis = {
  ADD_DELAY:'api/v1/admin/add-task-delay',
  getProjectChangeTimeLineData:'api/v1/admin/get-project-timeline-delay',
  GET_CLIENT_CREDENTIALS:'api/v1/admin/get-client-credentials',
  GET_PROJECT_MILESTONES:'api/v1/admin/get-milestones-admin',
  ADD_SUPPORT_MANAGER:'api/v1/admin/add-support-manager',
  PROJECT_DASHBOARD_STATS:'api/v1/admin/project-dashboard-get-stats-list',
  getTimelineData:'api/v1/admin/escl-timline',
  GET_ESCL_REOPEN:"api/v1/admin/escl-similar-reopened",
  GET_ESCL_SIMILER:'api/v1/admin/escl-similar',
  GET_PROJECT_DASHBOARD_STACKED_GRAPH_DATA:'api/v1/admin/project-dashboard-open-invoices-graph',
  ADD_PROJECT_TIMELINE_DELAY:'api/v1/admin/add-project-timeline-delay',
  UPDATE_DELAY_STATUS:'api/v1/admin/update-project-timeline-delay',
  DELAY_PROJECT_LISTING:'api/v1/admin/get-project-timeline-delay',
  GET_PROJECT_DASHBOARD_INVOICE_GRAPH_DATA:'api/v1/admin/project-dashboard-invoice-graph',
  GET_PROJECT_DASHBOARD_GRAPH_DATA:'api/v1/admin/project-dashboard-tier-graph',
  GET_PROJECT_COUNT:'api/v1/admin/project-dashboard-get-stats-count',
  GET_PROJECTS: 'api/v1/admin/get-projects',
  EMP_GET_EMPLOYEE_BY_TASKID:'api/v1/admin/view-task',
  GET_REOPENED_LIST:'api/v1/admin/escl-dashboard-reopened',
  GET_TAT_LIST:'api/v1/admin/escl-dashboard-tat',
  GET_ALL_MANAGERS: 'api/v1/admin/all-managers-list', // a
  GET_KICKOFF_INVOICE_TOTAL: 'api/v1/admin/get-kickoff-invoice-total',
  INVOICE_CARRYOVER_REASON: 'api/v1/admin/InvoiceCarryoverReasons',
  GET_TASKS_ADMIN: 'api/v1/admin/get-tasks-admin', // a
  GET_GRAPH_DATA:'api/v1/admin/escl-year-graph',
  GET_EMPLOYEE_FOR_DROPDOWN: 'api/v1/admin/get-employees',
  GET_EMPLOYEE: 'api/v1/admin/get-all-employees',
  GET_ATTENDANCE_REPORT_EXTRA: 'api/v1/admin/attendance-report-extra',
  GET_ATTENDANCE_REPORT: 'api/v1/admin/attendence-report',
  GET_USER_MONTHLY_TASKS: 'api/v1/admin/user-monthly-tasks',
  MAIL_TO_EMPLOYEES: 'api/v1/admin/mail-to-employees',
  GET_BANK_ACCOUNTS: 'api/v1/admin/get-bank-accounts',
  ADD_BANK_ACCOUNT: 'api/v1/admin/add-bank-account',
  GET_CLIENT: 'api/v1/admin/get-client',
  ADD_CLIENT: 'api/v1/admin/add-client',
  ADD_DEPARTMENT: 'api/v1/admin/add-department',
  ADD_DESIGNATION: 'api/v1/admin/add-designation',
  ADD_MOVE_TO_ACTIVE_CHECK: 'api/v1/admin/moveToActiveCheck',

  ADD_INTERNAL_REASON: 'api/v1/admin/add-internal-reason',
  GET_INTERNAL_REASON: 'api/v1/admin/get-internal-reason',
  ADD_CARRYOVER_REASON: 'api/v1/admin/InvoiceCarryoverReasons',
  GET_LEAVE_CATEGORIES: 'api/v1/admin/get-leave-categories',  // a/shared
  ADD_EDIT_LEAVE_CATEGORY: 'api/v1/admin/add-edit-leave-category', // a
  ADD_PRIORITY: 'api/v1/admin/add-priority', // a
  GET_PRIORITY: 'api/v1/admin/get-priority', // a
  ADD_ESCL_STATUS: 'api/v1/admin/add-escl-status', // a
  GET_ESCL_STATUS: 'api/v1/admin/get-escl-status', // a
  ADD_TAGS: 'api/v1/admin/add-tag', // a
  ADD_TECH_STACKS: 'api/v1/admin/add-tech-stack', // a
  ADD_REGION: 'api/v1/admin/add-region', // a
  GET_USER_ROLES: 'api/v1/admin/get-user-roles',
  ADD_USER_ROLES: 'api/v1/admin/add-user-roles', // a
  DAILY_REPORT: 'api/v1/admin/dailyReport', // a
  GET_PROJECT: 'api/v1/admin/get-project', // a
  GET_PROJECTS_LIST: 'api/v1/admin/get-projects-list', // a
  GET_DAILY_USER_REPORT: 'api/v1/admin/daily-user-report', // a
  ADMIN_DASHBOARD_WITH_PROJECTS: 'api/v1/admin/admin-dashboard-with-projects', // a
  DELETE_EMPLOYEE: 'api/v1/admin/delete-employee', // a
  ADD_EMPLOYEE: 'api/v1/admin/add-employee', // a
  FINANCE_DETAILS: 'api/v1/admin/finance-details', // a
  UPDATE_STATUS_MILESTONE_INVOICE: 'api/v1/admin/update-status-milestone-invoice', // a
  GET_MILESTONESHOME: 'api/v1/admin/get-milestonesHome', // a
  UPDATE_MILestoneStatus: 'api/v1/admin/update-milestoneStatus', // a
  GET_MONTHLY_USER_REPORT: 'api/v1/admin/get-monthly-user-report', // a
  GET_PROJECT_BY_ID: 'api/v1/admin/get-project-by-id', // a
  ADD_PROJECT: 'api/v1/admin/add-project', // a
  UPDATE_PROJECT_STATUS: 'api/v1/admin/update-project-status', // a
  ADMIN_UPDATE_PROJECT_PHASE_STATUS: 'api/v1/admin/admin-update-project-phase-status', // a
  UPDATE_PROJECT_CATEGORY: 'api/v1/admin/update-project-category', // a
  UPDATE_PROJECT_SUB_CATEGORY: 'api/v1/admin/update-project-sub-category', // a
  UPDATE_PROJECT_TAG: 'api/v1/admin/update-project-tag', // a
  PROJECT_FINANCE_DETAILS: 'api/v1/admin/project-finance-details', // a
  GET_PROJECT_MILESTONES_DETAIL: 'api/v1/admin/get-project-milestones-detail', // a
  PROJECT_REPORT_STATS: 'api/v1/admin/report-project-stat', // a
  GET_PROJECT_TASKS_WITH_STATUS: 'api/v1/admin/get-project-tasks-with-status', // a
  GET_MILESTONE_VISE_TASKS: 'api/v1/admin/get-milestone-vise-tasks', // a
  PROJECT_TODAY_REPORT: 'api/v1/admin/get-project-today-report', // a
  PROJECT_REPORT_DATES: 'api/v1/admin/report-project-dates', // a
  REPORT_PROJECT_USER: 'api/v1/admin/report-project-user', // a
  ADD_PORTAL_USER: 'api/v1/admin/add-portal-user', // a
  GET_PORTAL_USER: 'api/v1/admin/get-portal-user', // a
  GET_REPORTING_MANAGER: 'api/v1/admin/get-reporting-managers', // a
  ADMIN_MOVE_TO_NEXT_STAGE: 'api/v1/admin/admin-move-to-next-stage ',
  GET_ADMIN_LEAVE_REQUESTS: 'api/v1/admin/get-admin-leave-requests', // a
  ADMIN_LEAVE_REQUEST_ACTION: 'api/v1/admin/admin-leave-request-action', // a
  GET_ESCALATION: 'api/v1/admin/get-escl', // a
  GET_ESC_PROJECTS_LIST: 'api/v1/admin/get-esc-projects-list', // a
  GET_CLIENT_WITH_PROJECTS: 'api/v1/admin/admin-get-clients-with-projects', // a
  ADD_ESCALATION: 'api/v1/admin/add-escl',
  UPDATE_ESC_STATUS: 'api/v1/admin/update-escl-admin-status',
  DELETE_ESC: 'api/v1/admin/delete-escl',
  DELETE_ESC_ATTACHMENT: 'api/v1/admin/delete-escl-attachment',
  ALL_MILESTONE_INVOICES: 'api/v1/admin/all-milestone-invoices',
  ADMIN_PROJECT_TYPE_STAGES: 'api/v1/admin/admin-get-project-type-stages',
  ADMIN_GET_MY_PROJECTS: 'api/v1/admin/admin-get-my-projects',
  ADMIN_GET_EMP_DETAILS: 'api/v1/admin/admin-get-emp-details?',
  ADMIN_EMPLOYEE_MONTHLY_REPORT: 'api/v1/admin/admin-get-employee-monthly-report', // a
  PROJECT_BURNDOWN_CHART: 'api/v1/admin/get-project-burn-down-data', // a
  ADD_PROJECT_DOCS: 'api/v1/admin/add-project-docs',  // a/s/m
  GET_PROJECT_DOCS: 'api/v1/admin/get-project-docs',  // a/s/m
  REMOVE_PROJECT_DOC: 'api/v1/admin/remove-project-doc',  // a/s/m
  GET_SALE_REQUESTS: 'api/v1/admin/get-sale-requests',
  LOGOUTADMIN: 'api/v1/admin/logoutP', // a
  GET_RATES: 'api/v1/admin/get-Rates',  // all
  SET_RATES: 'api/v1/admin/set-Rates', // all
  UPDATE_ADMIN_PASSWORD: 'api/v1/admin/update-admin-password', // a
  GET_DATA_UPDATION_REQUESTS: 'api/v1/admin/get-data-updation-requests',
  UPDATE_STATUS_DATA_UPDATION_REQUESTS: 'api/v1/admin/update-status-data-updation-request',
  GET_ANALYTICS_DASHBOARD_DATA: 'api/v1/admin/get-analytics-dashboard-data',
  GET_ANALYTICS_REGION_VISE_REVENUE: 'api/v1/admin/get-analytics-region-vise-revenue',
  GET_ANALYTICS_MANAGER_REVENUE: 'api/v1/admin/get-analytics-manager-revenue',
  GET_TEAM_DATA: 'api/v1/admin/team-monthly-data',
  GET_MILESTONE_MONTHLY_DATA: 'api/v1/admin/milestone-monthly-data',
  BORROWED_TEAM_DATA: 'api/v1/admin/other-team-dev-data',
  GET_WITHOUT_MILESTONE_MONTHLY_DATA: 'api/v1/admin/get-project-without-milestone',
  GET_PROJECTS_WITH_KICKOFF: 'api/v1/admin/get-project-with-kickoff',
  GET_PROJECT_WITH_HOLD_STATUS: 'api/v1/admin/get-project-with-hold-status',
  GET_DONUT_CHART: 'api/v1/admin/get-donut-chart',
  GET_DONUT_CHART_CATEGORY: 'api/v1/admin/get-donut-chart-by-category',
  GET_UPFRONT_ACHEIVED_GRAPH: 'api/v1/admin/get-upfront-acheived-graph',
  GET_COST_FACTOR: 'api/v1/admin/get-cost-factor',
  SET_COST_FACTOR: 'api/v1/admin/set-cost-factor',
  PROJECT_TEAM_DATA: 'api/v1/admin/proect-team-data',
  SALARY_SHEET_HEADER: 'api/v1/admin/salary-sheet-header',
  SALARY_MONTHLY_GRAPH: 'api/v1/admin/salary-monthly-graph',
  SALARY_SHEET_FOR_MONTH: 'api/v1/admin/salary-sheet-for-month',
  SALARY_DEPARTMENT_COUNT_GRAPH: 'api/v1/admin/salary-department-count-graph',
  GENERATE_SLIP_FOR_MONTH: 'api/v1/admin/generate-sheet-for-month',
  ADD_BONUS: 'api/v1/admin/add-bonus',
  ADD_DECUTION: 'api/v1/admin/add-deduction',
  ADD_LEAVE: 'api/v1/admin/add-leave',
  GET_EXT_APP: 'api/v1/admin/get-ext-app',
  GET_PERMISSION: '/api/v1/admin/get-permissions',
  ADD_PERMISSION: 'api/v1/admin/add-permissions',
  ADD_EXT_APP: 'api/v1/admin/add-ext-app',
  GET_DAILY_PROJECT_LOGS: 'api/v1/admin/dailyProjectLogs',
  GET_DAILY_PROJECT_LOGS_DEATAILS: 'api/v1/admin/dailyProjectLogsAll',
  GET_PROJECT_REPORTING: 'api/v1/admin/expected-project-plan',
  PROJECT_BURNUP_CHART: 'api/v1/admin/get-project-burn-down-data-v2',
  SALARY_TECH_STACK: 'api/v1/admin/get-salary-by-tech',
  GET_DAILY_APPROVAL_TASKS: 'api/v1/admin/recentTaskApproval',
  APPROVE_REJECT_TASK: 'api/v1/admin/approveRejectTask',
  GET_TASK_STATUS: 'api/v1/admin/get-task-statuses',
  GET_MOVE_TO_ACTIVE_CHECK: 'api/v1/admin/moveToActiveCheck',
  GET_PROJECT_REPOS: 'api/v1/admin/get-project-repos',
  ADD_PROJECT_REPOS: 'api/v1/admin/add-project-repos',
  GET_EMPLOYEE_MONTHLY_REPORT_ADMIN: 'api/v1/admin/get-employee-monthly-report-admin',
  GET_EMP_DETAILS_ADMIN: 'api/v1/admin/get-emp-details-admin?',
  PERFORMANCE_REVIEW_ADMIN: 'api/v1/admin/performance-review-admin',
  MONTHLY_REPORT_EXPORT: 'api/v1/admin/monthlyReportExport',
  SAVE_PROJECT_CATEGORY: 'api/v1/admin/save-project-category',
  SAVE_PROJECT_SUB_CATEGORY: 'api/v1/admin/save-project-sub-category',
  GET_ROYO_TOKEN: 'api/v1/admin/get-royo-token',
  SAVE_ROYO_TOKEN: 'api/v1/admin/save-royo-token',
  SAVE_PROJECT_TYPE: 'api/v1/admin/save-project-type',
  GET_CARRY_LOGS: 'api/v1/admin/get-carrylogs',
  DELETE_CARRY_LOGS: 'api/v1/admin/delete-carrylogs',
  UPDATE_SALES_ROLE: 'api/v1/admin/update-sales-role',
  DELETE_REPO: 'api/v1/admin/delete-project-repo',
  GET_PROJECT_UPSELL: 'api/v1/admin/get-project-upsell',
  ADD_UPSELL_PROJECT_NOTE: 'api/v1/admin/add-upsell-project-note',
  CREATE_PROJECT_UPSELL: 'api/v1/admin/create-project-upsell ',
  GET_UPSELL_PROJECT_NOTE: 'api/v1/admin/get-upsell-project-note',
  GET_ESCL_COMMENT: 'api/v1/admin/get-escl-comment',
  ADD_ESCL_COMMENT: 'api/v1/admin/add-escl-comment',
  GET_MESSAGE: 'api/v1/admin/get-message',
  VIEW_MESSAGE: 'api/v1/admin/view-message',
  ADD_MESSAGE: 'api/v1/admin/add-message',
  DELETE_MESSAGE: 'api/v1/admin/delete-message',
  GET_FILES: 'api/v1/admin/get-files',
  GET_MAILS: 'api/v1/admin/get-mails',
  GET_MAIL_FOLDERS: 'api/v1/admin/get-mail-folders',
  GET_PROJECT_COMMENT: 'api/v1/admin/project-comment',
  SCREENSHOTS: 'api/v1/admin/screenshots',
  CAMERAS: 'api/v1/admin/cameras',
  TRACKING_STAT: 'api/v1/admin/tracking-stat',
  DOWNLOAD_SALES_ATTENDENCE: 'api/v1/admin/download-sales-attendence',
  BUSINESS_ENTITIES: 'api/v1/admin/business-entities',
  SAVE_PROJECT_COMMENT: 'api/v1/admin/project-comment',
  UPDATE_PHOTO: 'api/v1/admin/update-photo',
  KANBAN_TASK_LIST: 'api/v1/admin/kanban-task-list',
  GET_PROJECT_LIST: 'api/v1/admin/get-projects-list',
  EMP_GET_EMPLOYEE: 'api/v1/admin/get-all-employees',
  GET_TECH_STACKS: 'api/v1/global/get-tech-stacks',
  CHANGE_CSM:'api/v1/admin/escl-assign-csm',
  GANTT_CHART:'api/v1/admin/gantt-chart',
  GET_TOP_CARDS:'api/v1/admin/escl-dashboard',
  GET_STACKED_CHANNEL_GRAPH:'api/v1/admin/escl-channel-graph',
  GET_STACKED_GRAPH:'api/v1/admin/escl-reason-graph',
  GET_TIER3_LIST:'api/v1/admin/escl-dashboard-tier3',
  GET_IN_TIME: 'api/v1/admin/getTodayInTime',
  GET_ALL_CSMLIST:'api/v1/admin/all-csm-list',
  GET_TOTAL_ESCALATIONSL_LIST:'api/v1/admin/escl-dashboard-total',
};
