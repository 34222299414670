/** *data model used in different components * */

export class model {
  page?: any;
  data?: any;
  pageIndex?: any;
  dataSelect?: any;
  todayTask?: any;
  total?: any;
  loading?: boolean;
  loadingIn?: boolean;
  limit?: any;
  timezone?: any;
  type?: any;
  typeTask?: any;
  count?: any;
  user?: any;
  project?: any;
  milestone?: any;
  userId?: any;
  comment?: any = [];
  search?: any;
  langAr?: any;
  first?: any;
  firstArr?: any = [];
  attachments?: any = [];
  files?: any = [];
  bugsStatusArr?: any = [];
  issueArr?: any = [];
}
export class modelMenu {
  page?: any;
  data?: any;
  loading?: boolean;
  timezone?: any;
  type?: any;
  count?: any;
  search?: any;
}
export class milestone {
  project?: any;
  name?: any;
  startDate?: any;
  endDate?: any;
  status?: any;
  milestone?: any;
  employee?: any;
}
export class modelCommon {
  loading?: any;
  loadingAdd?: any;
}

export class user {
  email?: any;
  loginEmail?: any;
  password?: any;
  newpassword?: any;
  country?: any;
  city?: any;
  username?: any;
  gender?: any;
  phoneNumber?: any;
  type?: any;
  id?: any;
  image?: any;
  message?: any;
  avatarId?: any;
}

export class ProjectDetails {
  id?: string;
  project_name?: string;
}

export class ProjectMilestone {
  id?: number;
  project_type_id?: number;
  milestone_name?: string;
  total_tasks?: number;
  completed_tasks?: number;
  date?: any;
  all_task_count?: number;
  first_work_date?: any;
  markerColor?: string;
  progress?: Progress;
}

export class Progress {
  estimated_start: any;
  estimated_end: any;
}

export class BuildHistoryModel {
  data?: Array<BuildHistory>;
  search?: string;
  loadingIn?: boolean;
}

export class BuildHistory {
  id?: number;
  project_type_id?: number;
  milestone_name: string;
  total_tasks?: number;
  completed_tasks?: number;
  date?: any;
  all_task_count?: number;
}

export class EmployeeProjects {
  name: string;
  id: string;
}

export class EmployeeDetails {
  name: string;
  department_name: string;
  designation_name: string;
  emp_id: string;
  id: number;
  photo: any;
  photo1: string;
  manager_name: string;
  tech_stack_name: string;
  category_name: string;
  sub_category_name: string;
  emp_real_pnl: number;
  total_worked_min: any;
}

export class Calendar {
  date: any;
  month: any;
  day: string;
  completeDate: any;
  data?: any;
}

export const url = {
  imageUrl: 'https://cdn.aybiz.com/',
  mailLogo: 'assets/img/logoplaceholder.jpg',
  noImage: 'assets/img/noimage.jpg',
  imageLazyLoad: 'assets/img/newLoader.gif',
  placeImage: 'assets/img/place.png',
  userDefaultImage: 'assets/img/ic_username.svg'
};

// sidebar menu for Sub Managers
export const menu = [
  { name: 'Daily Log', value: 'daily-log' },
  { name: 'dashboard', value: 'dashboard' },
  { name: 'Project Daily Logs', value: 'project-daily-logs' },
  { name: 'Daily Task Approval', value: 'daily-task-approval' },
  // { name: 'Project Reporting', value: 'project-reporting'},
  {
    name: 'project',
    value: '',
    child: [
      { name: 'project', value: 'project-listing' },
      { name: 'milestone', value: 'milestone-listing' },
      { name: 'tasks', value: 'tasks-listing' },
      { name: 'Invoice Report', value: 'manager-invoice-report' },
      { name: 'milestone invoices', value: 'milestone-invoices' },
      { name: 'escalations', value: 'escalations' },
      // { name: 'updation requests', value: 'data-updation-requests' }
      { name: 'Upsell Logs', value: 'view-upsell-logs/0' },
      { name: 'Thread', value: 'thread-listing' },
      { name: 'Mails', value: 'mails/0' },
      { name: 'All Task', value: 'all-task-details' }
    ]
  },
  {
    name: 'My Team',
    value: '',
    child: [
      { name: 'My Leaves', value: 'my-leaves' },
      { name: 'Leave Requests', value: 'leave-requests' },
      { name: 'daily attendance', value: 'calendar' },
      // { name: 'tasks', value: 'tasks' }
    ]
  },
  // {
  //   name: 'finance',
  //   value: '',
  //   child: [
  //     // { name: 'milestone', value: 'milestone' },
  //     { name: 'milestone invoices', value: 'milestone-invoices' },
  //     // { name: 'Invoice Report', value: 'manager-invoice-report' }
  //   ]
  // },
  {
    name: 'reports',
    value: '',
    child: [
      // { name: 'daily attendance', value: 'calendar' },
      // { name: 'Project Analysis', value: 'report' },
      // { name: 'User Report', value: 'reportUser' },
      { name: 'Attendance Report', value: 'attendance-report' }
    ]
  },
  {
    name: 'settings',
    value: '',
    child: []
  }
];

// sidebar menu for Senior Managers
export const menuSeniorManager = [

  { name: 'Daily Log', value: 'daily-log' },
  { name: 'dashboard', value: 'dashboard' },
  { name: 'Project Daily Logs', value: 'project-daily-logs' },
  { name: 'Daily Task Approval', value: 'daily-task-approval' },
  // { name: 'Project Reporting', value: 'project-reporting'},
  {
    name: 'project',
    value: '',
    child: [
      { name: 'project', value: 'project-listing' },
      { name: 'Project Dashboard', value: 'project-dashboard' },
      { name: 'milestone', value: 'milestone-listing' },
      { name: 'tasks', value: 'tasks-listing' },
      { name: 'milestone invoices', value: 'milestone-invoices' },
      { name: 'Invoice Report', value: 'invoice-report' },
      // { name: 'escalations', value: 'escalations' },
      // { name: 'updation requests', value: 'data-updation-requests' }
      { name: 'Upsell Logs', value: 'view-upsell-logs/0' },
      { name: 'Thread', value: 'thread-listing' },
      { name: 'Mails', value: 'mails/0' },
      { name: 'All Task', value: 'all-task-details' },
      { name: 'Gantt-Charts', value: 'ganttChart' }
    ]
  },
  {
    name: 'My Team',
    value: '',
    child: [
      { name: 'My Leaves', value: 'my-leaves' },
      { name: 'Leave Requests', value: 'leave-requests' },
      { name: 'daily attendance', value: 'calendar' },
      // { name: 'tasks', value: 'tasks' }
    ]
  },
  // {
  //   name: 'finance',
  //   value: '',
  //   child: [
  //     { name: 'milestone', value: 'milestone' },
  //     { name: 'milestone invoices', value: 'milestone-invoices' },
  //     { name: 'Invoice Report', value: 'invoice-report' }
  //   ]
  // },
  {
    name: 'reports',
    value: '',
    child: [
      { name: 'Daily User Report', value: 'daily-user-report' },
      // { name: 'daily attendance', value: 'calendar' },
      // { name: 'Project Analysis', value: 'report' },
      // { name: 'User Report', value: 'reportUser' },
      // { name: 'Attendance Report', value: 'attendance-report' }
    ]
  },
  {
    name: 'settings',
    value: '',
    child: []
  }
];
export let menuDev
if(JSON.parse(localStorage.getItem('user_data')) && JSON.parse(localStorage.getItem('user_data')).id == 1710 ){
  menuDev = [
    // { name: 'dashboard', value: 'dev/dashboardev' },
    { name: 'monthly-report', value: 'dev/monthly-report' },
    { name: 'All Task list', value: 'dev/all-task-list' },
    { name: 'calendar', value: 'dev/daily-attendance' },
    { name: 'My Leaves', value: 'dev/my-leaves' },
    //{ name: 'monthly-report', value: 'dev/monthly-report' },
    { name: 'updation requests', value: 'dev/data-updation-requests' },
    { name: 'Daily Report', value: 'dev/daily-user-report' },//this route is only availabe for this 1710 employee id 
    { name: 'settings', value: '', child: [] },
    { name: 'All Task', value: 'dev/all-task-details' },
    
    
  ];
}else{
  menuDev = [
    // { name: 'dashboard', value: 'dev/dashboardev' },
    { name: 'monthly-report', value: 'dev/monthly-report' },
    { name: 'All Task list', value: 'dev/all-task-list' },
    { name: 'calendar', value: 'dev/daily-attendance' },
    { name: 'My Leaves', value: 'dev/my-leaves' },
    //{ name: 'monthly-report', value: 'dev/monthly-report' },
    { name: 'updation requests', value: 'dev/data-updation-requests' },
    
    { name: 'settings', value: '', child: [] },
    { name: 'All Task', value: 'dev/all-task-details' },
    
    
  ];
}
// sidebar menu for Developers






// sidebar menu for QA
export const menuQa = [
  { name: 'project', value: 'qa/dashboard' },
  // { name: 'issues', value: 'qa/issues' },
  { name: 'My Leaves', value: 'qa/my-leaves' },
  { name: 'calendar', value: 'qa/daily-attendance' },
  { name: 'updation requests', value: 'qa/data-updation-requests' },
  { name: 'settings', value: '', child: [] }
];

// sidebar menu for HR
export const menuHr = [
  { name: 'dashboard', value: 'hr/dashboard' },
  { name: 'employees', value: 'hr/employees' },
  // { name: 'salary', value: 'hr/salary-sheet' },
  { name: 'Leave Requests', value: 'hr/leave-requests-pro' },
  { name: 'updation requests', value: 'hr/data-updation-requests' },
  {
    name: 'reports',
    value: '',
    child: [
      { name: 'Attendance Report', value: 'hr/attendance-report' },
      { name: 'Monthly User Report', value: 'hr/monthly-user-report' },
      { name: 'CRM Daily Time Tracker', value: 'hr/crm-daily-time-tracker' }
    ]
  },
  { name: 'birthdays', value: 'hr/birthdays' },
  { name: 'Work From Home', value: 'hr/work-from-home' },
  {
    name: 'settings',
    value: '',
    child: [
      { name: 'daily attendance', value: 'hr/daily-attendance' },
      { name: 'Holiday Calendar', value: 'hr/calendar' },
      { name: 'Attendance Calendar', value: 'hr/attendance-calendar' }
    ]
  }
];

// sidebar menu for Finance
export const menuFinance = [
  { name: 'dashboard', value: 'finance/attendance' },
  { name: 'finance', value: 'finance/dashboard' },
  { name: 'milestone invoices', value: 'finance/milestone-invoices' },
  { name: 'project', value: 'finance/projects' },
  { name: 'Work From Home', value: 'finance/expenses' },
  { name: 'calendar', value: 'finance/daily-attendance' },
  { name: 'settings', value: '', child: [] }
];

// sidebar menu for Sales
export const menuSales = [
  // { name: 'dashboard', value: 'sales/dashboard' },
  { name: 'client', value: 'sales/client' },
  { name: 'project', value: 'sales/project' },
  { name: 'escalations', value: 'sales/escalations' },
  { name: 'sales request', value: 'sales/requests' },
  { name: 'daily attendance', value: 'sales/calendar' },
  { name: 'My Leaves', value: 'sales/my-leaves' },
  { name: 'updation requests', value: 'sales/data-updation-requests' },
  { name: 'settings', value: '', child: [] }
];

let isHidden=JSON.parse(localStorage.getItem('user_data')) && JSON.parse(localStorage.getItem('user_data')).hide_fin_data==1

// sidebar menu for Admin
export const menuAdmin = [
  { name: 'dashboard', value: 'admin/dashboard' },
  { name: 'esclDashboard', value: 'admin/escl_dashboard' },
  {
    name: 'project',
    value: '',
    child: [
      { name: 'project', value: 'admin/project' },
      { name: 'Project Dashboard', value: 'admin/projectDashboard' },
      { name: 'Delay Projects', value: 'admin/delay-projects' },
      { name: 'Project Daily Logs', value: 'admin/project-daily-logs' },
      { name: 'Daily Task Approval', value: 'admin/daily-task-approval' },
      // { name: 'Project Reporting', value: 'admin/project-reporting'},
      { name: 'escalations', value: 'admin/escalations' },
      { name: 'client', value: 'admin/client' },
      { name: 'sales request', value: 'admin/requests' },
      { name: 'updation requests', value: 'admin/data-updation-requests' },
      { name: 'Thread', value: 'admin/thread-listing' },
      { name: 'All Task', value: 'admin/all-task-details' },
      { name: 'Gantt-Charts', value: 'admin/ganttChart' },

    ]
  },
  {
    name: 'finance',
    value: '',
    
    child: [
      !isHidden && { name: 'milestone invoices', value: 'admin/milestone-invoices' },
      !isHidden && { name: 'finance', value: 'admin/finance' },
      { name: 'bank accounts', value: 'admin/bank-accounts' }
    ]
  },
  {
    name: 'reports',
    value: '',
    child: [
      // { name: 'Milestone', value: 'admin/milestone' },
      // { name: 'User Report', value: 'admin/userReport' },
      // { name: 'Daily Report', value: 'admin/dailyReport' },
      // { name: 'Project Report', value: 'admin/projectReport' },
      // { name: 'Attendance Report', value: 'admin/attendance-report' },
      { name: 'Daily user report', value: 'admin/daily-user-report' },
      { name: 'Monthly User Report', value: 'admin/monthly-user-report' },
      { name: 'CRM Daily Time Tracker', value: 'admin/crm-daily-time-tracker' }
    ]
  },
  {
    name: 'hr',
    value: '',
    child: [
      { name: 'employees', value: 'admin/employees' },
      //{ name: 'user', value: 'admin/user' },
      { name: 'salary', value: 'admin/salary-sheet' },
      { name: 'leaves', value: 'admin/leaves' },
      { name: 'royo chat user restriction', value: 'admin/royo-chat-user-restriction' }
    ]
  },
  {
    name: 'cruds',
    value: '',
    child: [
      { name: 'Tags', value: 'admin/tags' },
      { name: 'Status', value: 'admin/status' },
      { name: 'Internal Reason', value: 'admin/internalReason' },
      { name: 'Priority', value: 'admin/priority' },
      { name: 'Department', value: 'admin/department' },
      { name: 'Type of Panels', value: 'admin/designation' },
      { name: 'User Role', value: 'admin/userrole' },
      { name: 'Tech Stack', value: 'admin/techstack' },
      { name: 'Leave Categories', value: 'admin/leave-categories' },
      { name: 'Carryover Reasons', value: 'admin/carryover-reasons' },
      { name: 'Permission', value: 'admin/permissions' },
      { name: 'External App', value: 'admin/external-apps' },
      { name: 'Regions', value: 'admin/regions' },
      { name: 'Project Activate', value: 'admin/project-activate-check' },
      { name: 'Project Categories', value: 'admin/project-categories' },
      { name: 'Project Sub Categories', value: 'admin/project-sub-categories' },
      { name: 'Project Types', value: 'admin/project-types' },
      { name: 'Upsell Logs', value: 'admin/view-upsell-logs/0' }
    ]
  },
  {
    name: 'settings',
    value: '',
    child: [
      // {name:'Per Hour Price',value:'admin/per-hour-price'},
    ]
  }
];

// sidebar menu for Clients
export const menuClient = [
  { name: 'dashboard', value: 'admin/dashboard' },
  { name: 'project', value: 'admin/project' },
  { name: 'escalations', value: 'admin/escalations' },
  {
    name: 'settings',
    value: '',
    child: []
  }
];

// sidebar menu for Marketing
export const menuMarketing = [
  { name: 'dashboard', value: 'marketing/dashboard' },
  { name: 'calendar', value: 'marketing/daily-attendance' },
  { name: 'My Leaves', value: 'marketing/my-leaves' },
  { name: 'updation requests', value: 'marketing/data-updation-requests' },
  { name: 'settings', value: '', child: [] }
];

// sidebar menu for CSM
export const menuCSM = [
  {
    name: 'project',
    value: '',
    child: [
      { name: 'Dashboard', value: 'csm/dashboard' },
      { name: 'Projects', value: 'csm/project-listing' },
      { name: 'Project Dashboard', value: 'csm/project-dashboard' },
      { name: 'Pending Projects', value: 'csm/pending-project-listing' },
      { name: 'Project Daily Logs', value: 'csm/project-daily-logs' },
      { name: 'Daily Task Approval', value: 'csm/daily-task-approval' },
      { name: 'Escalations', value: 'csm/escalations' },
      { name: 'Client', value: 'csm/client' },
      { name: 'Thread', value: 'csm/thread-listing' },
      { name: 'Upsell Logs', value: 'csm/view-upsell-logs/0' },
    ]
  },
  {
    name: 'My Team',
    value: '',
    child: [
      { name: 'My Leaves', value: 'csm/my-leaves' },
      { name: 'daily attendance', value: 'csm/calendar' }
    ]
  },
  { name: 'settings', value: '', child: [] }
];

// all sidebar icons and fa class
export const icons = {
  'My Team': '/assets/img/ic_profile_active.png',
  'hr': '/assets/img/ic_profile_active.png',
  'dashboard_2.0': '/assets/img/ic_dashboard_active.png',
  'Daily Log': 'fa fa-home',
  'Project Daily Logs': 'fa fa-history',
  'Daily Task Approval': 'fa fa-list',
  'Project Reporting': 'fa fa-file',
  Tags: 'fa fa-tags',
  'Leave Categories': 'fa fa-briefcase',
  'Carryover Reasons': 'fa fa-briefcase',
  Status: 'fa fa-briefcase',
  'Internal Reason': 'fa fa-briefcase',
  Priority: 'fa fa-briefcase',
  Department: 'fa fa-briefcase',
  'Type of Panels': 'fa fa-briefcase',
  escalations: '/assets/img/ic_escalations_active.png',
  'sales request': 'fa fa-reply',
  'User Role': 'fa fa-briefcase',
  'Tech Stack': 'fa fa-briefcase',
  user: 'fa fa-users',
  cruds: 'fa fa-cogs',
  employees: 'fa fa-user-circle-o',
  client: 'fa fa-user-secret',
  dashboard: '/assets/img/ic_dashboard_active.png',
  'dashboard new': '/assets/img/ic_dashboard_active.png',
  calendar: 'fa fa-calendar',
  'daily attendance': 'fa fa-calendar',
  project: '/assets/img/ic_jobs_active.png',
  Milestone: '/assets/img/ic_milestones_active.png',
  milestone: '/assets/img/ic_milestones_active.png',
  tasks: 'fa fa-tasks',
  lock: 'fa fa-lock',
  settings: '/assets/img/ic_settings_active.png',
  'Holiday Calendar': 'fa fa-calendar-o',
  'Attendance Calendar': 'fa fa-calendar-o',
  issues: 'fa fa-exclamation-triangle',
  accepted: 'fa fa-briefcase',
  completed: 'fa fa-briefcase',
  declinedJobs: 'fa fa-briefcase',
  'reports': '/assets/img/ic_reports_active.png',
  'Project Analysis': 'fa fa-briefcase',
  'Project Report': 'fa fa-briefcase',
  'User Report': 'fa fa-briefcase',
  'Daily Report': 'fa fa-briefcase',
  'Attendance Report': 'fa fa-briefcase',
  'Daily user report': 'fa fa-briefcase',
  'Monthly User Report': 'fa fa-briefcase',
  cms: 'fa fa-briefcase',
  accessControl: 'fa fa-briefcase',
  faq: 'fa fa-briefcase',
  howItWorks: 'fa fa-briefcase',
  contactUs: 'fa fa-briefcase',
  termsAndCondition: 'fa fa-briefcase',
  termsAndConditionForUser: 'fa fa-briefcase',
  aboutUs: 'fa fa-briefcase',
  'updation requests': 'fa fa-refresh',
  notifications: 'fa fa-briefcase',
  copyCategory: 'fa fa-briefcase',
  automaticPush: 'fa fa-briefcase',
  languageLabel: 'fa fa-briefcase',
  notificationSound: 'fa fa-briefcase',
  seo: 'fa fa-briefcase',
  esclDashboard: 'fa fa-area-chart',
  transaction: 'fa fa-briefcase',
  general: 'fa fa-briefcase',
  socialMedia: 'fa fa-briefcase',
  analytic: 'fa fa-briefcase',
  emailTemplate: 'fa fa-briefcase',
  orderListing: 'fa fa-briefcase',
  subscription: 'fa fa-briefcase',
  birthdays: 'fa fa-birthday-cake',
  'Work From Home': 'fa fa-desktop',
  myTeam: 'fa fa-users',
  'My Leaves': 'fa fa-list',
  'Leave Requests': 'fa fa-list',
  leaves: 'fa fa-list',
  'Daily User Report': 'fa fa-users',
  'milestone invoices': 'fa fa-file',
  'Invoice Report': '/assets/img/ic_reports_active.png',
  finance: '/assets/img/ic_milestones_active.png',
  'bank accounts': 'fa fa-university',
  'salary': '/assets/img/ic_dashboard_active.png',
  'monthly-report': 'fa fa-line-chart',
  'All Task list': 'fa fa-list',
};

export const Month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const Week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const BtnText = {
  week: 'Week',
  month: 'Month',
  year: 'Year',
  daily: 'Daily',
  current: 'Today',
  previous: 'Previous',
  next: 'Next'
};

export const tabArray = [
  { label: '', name: 'customer', value: [0, 1, 2, 3, 4] },
  { label: '', name: 'serviceProvider', value: [0, 1, 2, 4] },
  { label: '', name: 'category', value: [0, 1, 2, 3] },
  { label: '', name: 'services', value: [0, 1, 2, 3] },
  { label: '', name: 'question', value: [0, 1, 2, 3] },
  { label: '', name: 'option', value: [1] },
  { label: '', name: 'speciality', value: [0, 1, 2] },
  { label: '', name: 'decline', value: [0] },
  { label: 'decline', name: 'questions', value: [2] },
  { label: 'decline', name: 'options', value: [1, 2] },
  { label: '', name: 'coupon', value: [0, 1, 2] },
  { label: 'jobs', name: 'requested', value: [0] },
  { label: 'jobs', name: 'accepted', value: [0] },
  { label: 'jobs', name: 'completed', value: [0] },
  { label: 'jobs', name: 'declinedJobs', value: [0] },
  { label: '', name: 'admin', value: [0, 1, 2] },
  { label: '', name: 'accessControl', value: [0] },
  { label: '', name: 'faq', value: [0, 1, 2] },
  { label: '', name: 'termsAndCondition', value: [0, 1, 2] },
  { label: '', name: 'termsAndConditionForUser', value: [0, 1, 2] },
  { label: '', name: 'aboutUs', value: [0, 1, 2] },
  { label: '', name: 'contactUs', value: [0, 1, 2] },
  { label: '', name: 'howItWorks', value: [0, 1, 2] },
  { label: '', name: 'notifications', value: [0] },
  { label: '', name: 'billingInfo', value: [0, 1, 2, 3] },
  { label: '', name: 'automaticPush', value: [0] },
  { label: '', name: 'createJob', value: [1] },
  { label: 'setting', name: 'languageLabel', value: [0, 1, 2] },
  { label: 'setting', name: 'notificationSound', value: [0] },
  { label: 'setting', name: 'seo', value: [0] },
  { label: 'setting', name: 'socialMedia', value: [0] },
  { label: 'setting', name: 'emailTemplate', value: [0, 1, 2] },
  { label: 'setting', name: 'general', value: [0] },
  { label: 'setting', name: 'analytic', value: [0] },
  { label: '', name: 'transaction', value: [0] },
  { label: '', name: 'orderListing', value: [0, 5] },
  { label: '', name: 'subscription', value: [0, 1, 2] }
];
export const optionArray = [
  { name: 'view', value: false }, // 0
  { name: 'add', value: false }, // 1
  { name: 'edit', value: false }, // 2
  { name: 'delete', value: false }, // 3
  { name: 'status', value: false }, // 4
  { name: 'refund', value: false } // 5
];

export const permission = { status: 'default' };
for (const x of tabArray) {
  const array1 = {};
  for (const y of x.value) {
    array1[optionArray[y].name] = optionArray[y].value;
  }
  permission[x.name] = array1;
}

export const invoiceStatusArray = [
  { id: '1', name: 'Open' },
  { id: '2', name: 'Invoice Raised' },
  { id: '3', name: 'R & C' },
  { id: '4', name: 'Invoice Paid' }
];

export const projectPhaseStatusArray = [
  { id: '1', name: 'Pre Sales' },
  { id: '2', name: 'Kick Off' },
  { id: '3', name: 'Design' },
  { id: '4', name: 'Development' },
  { id: '5', name: 'UAT' },
  { id: '6', name: 'Live' },
  { id: '7', name: 'Finished' }
];

export const taskApprovalStatusArray = [
  { id: '1', name: 'Pending', value: 'n' },
  { id: '2', name: 'Approved', value: 'y' },
  { id: '3', name: 'Rejected', value: '' }
];

export const projectTypes = [
  { id: 1, name: 'Custom' },
  { id: 1, name: 'WL' },
  { id: 1, name: 'WL+Custom' },
  { id: 1, name: 'T&M' },
  { id: 1, name: 'Design' },
  { id: 1, name: 'Hourly' }
];

export const TaskStatus = [
  {
    name: 'Pending',
    value: 1,
    selected: true,
    class: 'dark-blue',
    color: '#558DD4'
  },
  {
    name: 'In Progress',
    value: 2,
    selected: true,
    class: 'yellow-type',
    color: '#F5AC5E'
  },
  {
    name: 'Paused',
    value: 3,
    selected: true,
    class: 'green',
    color: '#8DB05A'
  },
  {
    name: 'Completed',
    value: 4,
    selected: true,
    class: 'red-dark',
    color: '#FE6383'
  },
  { name: 'Reopen', value: 5, selected: true, class: 'blue', color: '#558DD4' },
  { name: 'Closed', value: 6, selected: true, class: 'red', color: '#C31925' }
];

export const MilestoneColors = [
  '#08e3f9',
  '#F5AC5E',
  '#F5AC5E',
  '#8DB05A',
  '#FE6383',
  '#558DD4',
  '#C31925',
  '#6841c5',
  '#ef18ac',
  '#558DD4'
];

export const ProjectColors = [
  'rgb(139, 197, 65)',
  'rgb(111, 169, 247)',
  'rgb(225, 94, 126)',
  'rgb(153, 120, 230)',
  'rgb(195, 197, 65)',
  'rgb(197, 120, 65)',
  'rgb(102, 218, 202)',
  'rgb(225, 94, 197)',
  'rgba(44, 123, 43, 0.8)',
  'rgb(28, 140, 99)',
  'rgb(226, 191, 92)',
  'rgb(139, 197, 65)',
  'rgb(111, 169, 247)',
  'rgb(225, 94, 126)',
  'rgb(153, 120, 230)',
  'rgb(195, 197, 65)',
  'rgb(197, 120, 65)',
  'rgb(102, 218, 202)',
  'rgb(225, 94, 197)',
  'rgba(44, 123, 43, 0.8)',
  'rgb(28, 140, 99)',
  'rgb(226, 191, 92)'
];

export const TimeColors = [
  'rgb(122, 171, 60)',
  'rgb(87, 136, 202)',
  'rgb(185, 75, 102)',
  'rgb(116, 92, 173)',
  'rgb(163, 165, 50)',
  'rgb(165, 88, 34)',
  'rgb(80, 173, 160)',
  'rgb(181, 75, 158)',
  'rgba(44, 123, 43, 0.8)',
  'rgb(23, 107, 76)',
  'rgb(193, 155, 47)',
  'rgb(122, 171, 60)',
  'rgb(87, 136, 202)',
  'rgb(185, 75, 102)',
  'rgb(116, 92, 173)',
  'rgb(163, 165, 50)',
  'rgb(165, 88, 34)',
  'rgb(80, 173, 160)',
  'rgb(181, 75, 158)',
  'rgba(44, 123, 43, 0.8)',
  'rgb(23, 107, 76)',
  'rgb(193, 155, 47)'
];

export const TaskColors = [
  'rgb(212, 254, 159)',
  '#B1D1FC',
  '#F4A5B8',
  'rgba(177, 152, 236, 0.71)',
  'rgb(247, 252, 177)',
  'rgb(254, 203, 159)',
  '#9ffefe7d',
  'rgba(244, 165, 231, 0.8)',
  'rgba(118, 210, 138, 0.7)',
  'rgba(63, 195, 147, 0.56)',
  'rgba(226, 191, 92, 0.48)',
  'rgb(212, 254, 159)',
  '#B1D1FC',
  '#F4A5B8',
  'rgba(177, 152, 236, 0.71)',
  'rgb(247, 252, 177)',
  'rgb(254, 203, 159)',
  '#9ffefe7d',
  'rgba(244, 165, 231, 0.8)',
  'rgba(118, 210, 138, 0.7)',
  'rgba(63, 195, 147, 0.56)',
  'rgba(226, 191, 92, 0.48)'
];

export const DocType = [
  {
    id: 1,
    name: 'Media',
    type: 'file'
  },
  {
    id: 2,
    name: 'Url',
    type: 'text'
  },
  {
    id: 3,
    name: 'Text',
    type: 'text'
  }
];
