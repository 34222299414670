import { Injectable } from '@angular/core';
import {
  Router
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ClientAuthService {

  constructor(private router: Router) {}

  canActivate() {
    const token = localStorage.getItem('api_token');
    if (token && localStorage.getItem('desigination')) {
      return true;
    }
    this.router.navigate(['/client-login']);
    return false;
  }
  
}
