import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { AdminService } from './services/admin.service';
import { VersionCheckService } from './services/version-check.service';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'codebrew';
  timeLeft = 0;
  interval;
  startTimerr;
  stopTimerr;

  constructor(
    location: PlatformLocation,
    public admin: AdminService,
    private versionCheckService: VersionCheckService,
    private swUpdate: SwUpdate,
  ) {
    /**
     * onPopState is used to handle the back button event
     */
    location.onPopState(() => {
      this.admin.closeSwal();
    });

    this.startTimerr = this.admin.startTimerr.subscribe(x => {
      if (x) {
        this.startTimer();
      }
    });

    this.stopTimerr = this.admin.stopTimerr.subscribe(x => {
      if (x) {
        this.pauseTimer();
      }
    });
  }

  ngOnInit() {
    // this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    this.checkNewVersion();
  }

  startTimer() {
    const currentTask = JSON.parse(localStorage.getItem('currentTask'));
    if (currentTask != null) {
      this.timeLeft = (currentTask.work_minutes * 60);
      this.interval = setInterval(() => {
        this.timeLeft++;

      }, 1000);
    }
  }

  pauseTimer() {
    const currentTask = JSON.parse(localStorage.getItem('currentTask'));
    if (!!currentTask) {
      currentTask.work_minutes = (Math.floor(this.timeLeft / 60)).toFixed(0);
      localStorage.setItem('currentTask', JSON.stringify(currentTask));
      clearInterval(this.interval);
      this.admin.closeStopTimerOff(true);
      this.timeLeft = 0;
    }
  }

  ngOnDestroy() {
    const currentTask = JSON.parse(localStorage.getItem('currentTask'));
    currentTask.work_minutes = (Math.floor(this.timeLeft / 60)).toFixed(0);
    localStorage.setItem('currentTask', JSON.stringify(currentTask));
    this.stopTimerr.unsubscribe();
    this.stopTimerr.unsubscribe();
  }

  checkNewVersion() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available. Load New Version?")) {
          window.location.reload();
        }
      });
    }
  }

}
