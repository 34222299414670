import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthFinanceService implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    const token = localStorage.getItem('api_token');
    if (
      token &&
      localStorage.getItem('desigination') &&
      localStorage.getItem('desigination') === 'Finance'
    ) {
      return true;
    }
    if (
      token &&
      localStorage.getItem('desigination') &&
      localStorage.getItem('desigination') !== 'Finance'
    ) {
      return false;
    }
    this.router.navigate(['']);
    return false;
  }
}
