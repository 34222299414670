


import { NgModule } from '@angular/core';
import { PreloadAllModules, PreloadingStrategy, RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { PageNotfoundComponent } from './publicPages/page-notfound/page-notfound.component';
import { LoginComponent } from './publicPages/login/login.component';
import { AuthService } from './services/auth.service';
import { AuthDevService } from './services/auth-dev.service';
import { AuthQaService } from './services/auth-qa.service';
import { AuthHrService } from './services/auth-hr.service';
import { AuthLoginService } from './services/auth-login.service';
import { AdminAuthService } from './services/admin-auth.service';
import { AuthFinanceService } from './services/auth-finance.service';
import { AuthSalesService } from './services/auth-sales.service';
import { AdminLoginComponent } from './publicPages/admin-login/admin-login.component';
import { ClientAuthService } from './services/client-auth.service';
import { MarketingGuard } from './services/marketing.guard';
import { CSMGuard } from './services/csm.guard';

const appRoutes: Routes = [
  // { path: '', pathMatch: 'full', loadChildren: './layout/layout.module#LayoutModule' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthLoginService] },
  { path: 'admin-login', component: AdminLoginComponent, canActivate: [AuthLoginService] },
  { path: 'client-login', loadChildren: './publicPages/client-login/client-login.module#ClientLoginModule', canActivate: [AuthLoginService] },
  { path: '', canActivate: [AuthService], loadChildren: './layout/layout.module#LayoutModule' },
  { path: 'dev', canActivate: [AuthDevService], loadChildren: './developer/developer.module#DeveloperModule' },
  { path: 'qa', canActivate: [AuthQaService], loadChildren: './qa/qa.module#QaModule' },
  { path: 'hr', canActivate: [AuthHrService], loadChildren: './hr/hr.module#HrModule' },
  { path: 'finance', canActivate: [AuthFinanceService], loadChildren: './finance/finance.module#FinanceModule' },
  { path: 'sales', canActivate: [AuthSalesService], loadChildren: './sales/sales.module#SalesModule' },
  { path: 'admin', canActivate: [AdminAuthService], loadChildren: './admin/admin.module#AdminModule' },
  {
    path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    canActivate: [ClientAuthService]
  },
  {
    path: 'marketing', canActivate: [MarketingGuard], loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'csm', canActivate: [CSMGuard], loadChildren: () => import('./csm/csm.module').then(m => m.CSMModule)
  },
  { path: '**', component: PageNotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    useHash: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})

export class RoutingModule { }


