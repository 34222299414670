export const ManagerApis = {
  getProjectChangeTimeLineData:'api/v1/manager/get-project-timeline-delay',
  GET_CLIENT_CREDENTIALS:'api/v1/manager/get-client-credentials',
  ADD_SUPPORT_MANAGER:'api/v1/manager/add-support-manager',
  GET_BANK_ACCOUNTS: 'api/v1/manager/get-bank-accounts',
  GET_PROJECT_DASHBOARD_STACKED_GRAPH_DATA:'api/v1/manager/project-dashboard-open-invoices-graph',
  GET_PROJECT_COUNT:'api/v1/manager/project-dashboard-get-stats-count',
  PROJECT_DASHBOARD_STATS:'api/v1/manager/project-dashboard-get-stats-list',
  GET_ALL_MANAGERS: 'api/v1/manager/all-managers-list',
  GET_PROJECT_DASHBOARD_INVOICE_GRAPH_DATA:'api/v1/manager/project-dashboard-invoice-graph',
  GET_PROJECT_DASHBOARD_GRAPH_DATA:'api/v1/manager/project-dashboard-tier-graph',
  ADD_PROJECT_TIMELINE_DELAY:'api/v1/manager/add-project-timeline-delay',
  ADD_DELAY:'api/v1/manager/add-task-delay',
  EMP_GET_EMPLOYEE_BY_TASKID:'api/v1/manager/view-task',
  GET_EMPLOYEES: 'api/v1/manager/get-employees',
  GET_MANAGER_HOME: 'api/v1/manager/get-manager-home',
  MANAGER_MILESTONE_INVOICES: 'api/v1/manager/milestone-invoices',
  ALL_MILESTONE_INVOICES: 'api/v1/manager/all-milestone-invoices',
  ADD_MILESTONE_INVOICE: 'api/v1/manager/add-milestone-invoice',
  DELETE_MILESTONE_INVOICE: 'api/v1/manager/delete-milestone-invoice',
  GET_KICKOFF_INVOICE_TOTAL: 'api/v1/manager/get-kickoff-invoice-total',
  INVOICE_CARRYOVER_REASON: 'api/v1/manager/InvoiceCarryoverReasons',
  GET_MILESTONES: 'api/v1/manager/get-milestones',
  GET_TASK: 'api/v1/manager/get-tasks',
  CREAT_TASK_COMMENT: 'api/v1/manager/create-task-comment',
  GET_TASK_COMMENTS: 'api/v1/manager/get-task-comments',
  GET_TASK_STATUS: 'api/v1/manager/get-task-statuses',
  GET_PROJECT: 'api/v1/manager/get-project',
  GET_PROJECT_BY_ID: 'api/v1/manager/get-project-by-id',
  ADD_PROJECT: 'api/v1/manager/add-project',
  UPDATE_PROJECT_STATUS: 'api/v1/manager/update-project-status',
  UPDATE_PROJECT_PHASE_STATUS: 'api/v1/manager/update-project-phase-status',
  GET_ESCALATION: 'api/v1/manager/get-escl',
  UPDATE_ESCL_STATUS: 'api/v1/manager/update-escl-status',
  DELETE_TASK: 'api/v1/manager/delete-task',
  MULTIPLE_DELETE: 'api/v1/manager/delete-multiple-tasks',
  ADD_TASK: 'api/v1/manager/add-task',
  EDIT_TASK: 'api/v1/manager/edit-task',
  UPDATE_STATUS_MILESTONE_INVOICE:
    'api/v1/manager/update-status-milestone-invoice',
  ADD_MILESTONES: 'api/v1/manager/add-project-milestone',
  GET_MANAGER_PROJECT_MILESTONES_DETAIL:
    'api/v1/manager/get-project-milestones-detail',
  MARK_USER_ABSENT: 'api/v1/manager/update-attendence-status',
  GET_ATTENDANCE_REPORT: 'api/v1/manager/attendence-report',
  GET_ATTENDANCE_REPORT_EXTRA: 'api/v1/manager/attendance-report-extra',
  GET_ALL_BUGS: 'api/v1/manager/get-all-bugs',
  GET_MANAGER_PROJECTS_LIST: 'api/v1/manager/get-manager-projects-list',
  GET_DAILY_USER_REPORT: 'api/v1/manager/daily-user-report',
  MANAGER_GET_USER_MONTHLY_TASKS: 'api/v1/manager/user-monthly-tasks',
  EMP_GET_EMPLOYEE: 'api/v1/manager/get-all-employees',
  EMP_GET_CLIENT: 'api/v1/manager/get-client',
  REPORT_USER_CSV: 'api/v1/manager/report-user-csv',
  REPORT_PROJECT_COMPLETE: 'api/v1/manager/report-project-complete',
  ADD_PROJECT_MEMBER: 'api/v1/manager/add-project-member',
  GET_PROJECT_MEMBERS: 'api/v1/manager/get-project-members',
  CHANGE_PROJECT_DEVELOPER: 'api/v1/manager/change-project-developer',
  GET_MANAGER_PROJECT_TASKS_WITH_STATUS:
    'api/v1/manager/get-project-tasks-with-status',
  UPDATE_PROJECT_DESIGN_PHASE_DOC:
    'api/v1/manager/update-project-design-phase-doc',
  MOVE_TASK_ORDER: 'api/v1/manager/move-task-order',
  GET_TASK_TYPES: 'api/v1/manager/get-task-types',
  GET_TASK_BY_ID: 'api/v1/manager/getTaskById',
  UPDATE_MILESTONE_STATUS: 'api/v1/manager/update-milestone-status',
  SAVE_MILESTONES_ORDER: 'api/v1/manager/save-milestones-order',
  GET_PROJECT_TYPE_MILESTONES: 'api/v1/global/get-project-type-milestones',
  EMP_PROJECT_FINANCE_DETAILS: 'api/v1/manager/project-finance-details',
  EMP_GET_MILESTONE_VISE_TASKS: 'api/v1/manager/get-milestone-vise-tasks',
  REPORT_TASK_HOURS_DIFFERENCE: 'api/v1/manager/report-task-hours-difference',
  REPORT_GENERAL: 'api/v1/manager/report-general',
  REPORT_PROJECT_CSV: 'api/v1/manager/report-project-csv',
  REPORT_USER: 'api/v1/manager/report-user',
  GET_TASKS_SPRINT_FOR_PROJECTS: 'api/v1/manager/get-tasks-sprint-for-project',
  GET_MANAGER_TODAY_TASKS: 'api/v1/manager/get-today-tasks',
  GET_MANAGER_MISSED_TASKS: 'api/v1/get-manager-missed-tasks',
  GET_MANAGER_LEAVE_REQUESTS: 'api/v1/manager/get-leave-requests',
  MANAGER_LEAVE_REQUEST_ACTION: 'api/v1/manager/leave-request-action',
  EMP_MOVE_TO_NEXT_STAGE: 'api/v1/manager/move-to-next-stage',
  EMP_PROJECT_TYPE_STAGES: 'api/v1/manager/get-project-type-stages',
  GET_MY_PROJECTS: 'api/v1/manager/get-my-projects',
  GET_EMP_DETAILS: 'api/v1/manager/get-emp-details?',
  EMPLOYEE_MONTHLY_REPORT: 'api/v1/manager/get-employee-monthly-report',
  EMP_PROJECT_BURNDOWN_CHART: 'api/v1/manager/get-project-burn-down-data',
  ADD_PROJECT_DOCS: 'api/v1/manager/add-project-docs',  // a/s/m
  GET_PROJECT_DOCS: 'api/v1/manager/get-project-docs',  // a/s/m
  REMOVE_PROJECT_DOC: 'api/v1/manager/remove-project-doc',  // a/s/m
  GET_MEETINGS: 'api/v1/manager/get-meetings',
  CREATE_MEETING: 'api/v1/manager/create-meeting',
  GET_DAILY_ATTENDANCE: 'api/v1/manager/get-daily-attendance',
  GET_ANALYTICS_DASHBOARD_DATA: 'api/v1/manager/get-analytics-dashboard-data',
  GET_ANALYTICS_REGION_VISE_REVENUE: 'api/v1/manager/get-analytics-region-vise-revenue',
  GET_ANALYTICS_MANAGER_REVENUE: 'api/v1/manager/get-analytics-manager-revenue',
  GET_UPFRONT_ACHEIVED_GRAPH: 'api/v1/manager/get-upfront-acheived-graph',
  GET_DONUT_CHART: 'api/v1/manager/get-donut-chart',
  GET_DONUT_CHART_CATEGORY: 'api/v1/manager/get-donut-chart-by-category',
  GET_TEAM_DATA: 'api/v1/manager/team-monthly-data',
  GET_MILESTONE_MONTHLY_DATA: 'api/v1/manager/milestone-monthly-data',
  GET_WITHOUT_MILESTONE_MONTHLY_DATA: 'api/v1/manager/get-project-without-milestone',
  GET_PROJECTS_WITH_KICKOFF: 'api/v1/manager/get-project-with-kickoff',
  GET_PROJECT_WITH_HOLD_STATUS: 'api/v1/manager/get-project-with-hold-status',
  PROJECT_TEAM_DATA: 'api/v1/manager/proect-team-data',
  BORROWED_TEAM_DATA: 'api/v1/manager/other-team-dev-data',
  ADD_DAILY_PROJECT_LOG: 'api/v1/manager/dailyProjectLog',
  GET_DAILY_PROJECT_LOGS: 'api/v1/manager/dailyProjectLogs',
  GET_DAILY_PROJECT_LOGS_DEATAILS: 'api/v1/manager/dailyProjectLogsAll',
  PROJECT_PLAN_DATE: 'api/v1/manager/expected-project-plan-date',
  GET_PROJECT_REPORTING: 'api/v1/manager/expected-project-plan',
  UPDATE_PROJECT_CATEGORY: 'api/v1/manager/update-project-category', // a
  UPDATE_PROJECT_SUB_CATEGORY: 'api/v1/manager/update-project-sub-category', // a
  UPDATE_PROJECT_TAG: 'api/v1/manager/update-project-tag', // a
  EMP_PROJECT_BURNUP_CHART: 'api/v1/manager/get-project-burn-down-data-v2',
  GET_DAILY_APPROVAL_TASKS: 'api/v1/manager/recentTaskApproval',
  APPROVE_REJECT_TASK: 'api/v1/manager/approveRejectTask',
  CHANGE_EMP_MANAGER: 'api/v1/manager/change-emp-manager',
  GET_PROJECT_REPOS: 'api/v1/manager/get-project-repos',
  ADD_PROJECT_REPOS: 'api/v1/manager/add-project-repos',
  DELETE_REPO: 'api/v1/manager/delete-project-repo',
  GET_EMPLOYEE_REPORT_MAN: 'api/v1/manager/get-employee-monthly-report-man',
  GET_EMP_DETAILS_MAN: 'api/v1/manager/get-emp-details-man?',
  PERFORMANCE_REVIEW_MAN: 'api/v1/manager/performance-review-man',
  GET_CARRY_LOGS: 'api/v1/manager/get-carrylogs',
  DELETE_CARRY_LOGS: 'api/v1/manager/delete-carrylogs',
  ADD_UPSELL_PROJECT_NOTE: 'api/v1/manager/add-upsell-project-note',
  CREATE_PROJECT_UPSELL: 'api/v1/manager/create-project-upsell ',
  GET_UPSELL_PROJECT_NOTE: 'api/v1/manager/get-upsell-project-note',
  GET_PROJECT_UPSELL: 'api/v1/manager/get-project-upsell',
  GET_ESCL_COMMENT: 'api/v1/manager/get-escl-comment',
  ADD_ESCL_COMMENT: 'api/v1/manager/add-escl-comment',
  GET_MESSAGE: 'api/v1/manager/get-message',
  VIEW_MESSAGE: 'api/v1/manager/view-message',
  ADD_MESSAGE: 'api/v1/manager/add-message',
  GET_PROJECTS_LIST: 'api/v1/manager/get-projects-list',
  GET_FILES: 'api/v1/manager/get-files',
  ZOOM_CREATE_MEETING: 'api/v1/manager/zoom/createMeeting',
  ZOOM_MEETINGS: 'api/v1/manager/zoom/meetings',
  ZOOM_VIEW_USERS: 'api/v1/manager/zoom/viewUsers',
  ZOOM_RECORDINGS: 'api/v1/manager/zoom/recordings',
  ZOOM_PARTICIPANTS: 'api/v1/manager/zoom/participants',
  ZOOM_SEND_INVITE_MEET: 'api/v1/manager/zoom/sendInvitesMeet',
  ESCL_NOTIFICATIONS: 'api/v1/manager/escl-notifications',
  GET_MAILS: 'api/v1/manager/get-mails',
  GET_MAIL_FOLDERS: 'api/v1/manager/get-mail-folders',
  ADD_CLIENT: 'api/v1/manager/add-client',
  GET_PROJECT_COMMENT: 'api/v1/manager/project-comment',
  SAVE_PROJECT_COMMENT: 'api/v1/manager/project-comment',
  KANBAN_TASK_LIST: 'api/v1/manager/kanban-task-list',
  GET_PROJECT_LIST: 'api/v1/manager/get-projects-list',
  MULTIPLE_COMPLETE:'api/v1/manager/complete-multiple-tasks',
  CHANGE_CSM:'api/v1/manager/escl-assign-csm',
  GANTT_CHART:'api/v1/manager/gantt-chart'
};
