import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  totalRequests = 0;

  constructor(private admin: AdminService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.admin.showLoader) {
      this.admin.loader(true);
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.admin.loader(false);
        }
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.totalRequests++;
            // if (this.totalRequests == 1) {
              this.admin.logout();
              this.admin.error(err.error.error);
            // }
          } else {
            this.admin.errorAlert(err.error.message, false);
          }
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      })
    );
  }
}
