import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {

  constructor(private router: Router) { }

  canActivate() {
    const token = localStorage.getItem('api_token');
    if (!token) {
      return true;
    }
    const type_role = localStorage.getItem('role_type');
    const type = localStorage.getItem('desigination');
    if (type_role) {
      this.router.navigate(['admin/client']);
    } else if (type && type === 'Manager') {
      this.router.navigate(['dashboard']);
    } else if (type && type === 'Developer') {
      this.router.navigate(['dev/dashboardev']);
    } else if (type && type === 'Tester') {
      this.router.navigate(['qa/dashboard']);
    } else if (type && type === 'HR') {
      // this.router.navigate(['hr/employees']);
      this.router.navigate(['hr/dashboard']);
    } else if (type && type === 'Client') {
      this.router.navigate(['client/dashboard']);
    } else {
      localStorage.removeItem('api_token');
      localStorage.removeItem('project');
      localStorage.removeItem('desigination');
      localStorage.removeItem('role_type');
      localStorage.removeItem('is_senior');
      this.router.navigate(['/login']);
    }

    return false;
  }
}
