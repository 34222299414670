export const CommonApis = {
  GET_BANK_ACCOUNTS: 'api/v1/common/get-bank-accounts',
  GET_ALL_MANAGERS: 'api/v1/common/all-managers-list',
  GET_ALL_MANAGERS_LIST: 'api/v1/common/get-all-managers',
  GET_RECENT_PROJECT_ACTIVITIES: 'api/v1/common/get-recent-project-activities',
  APPLY_LEAVE: 'api/v1/common/apply-leave',
  GET_EMPLOYEE_LEAVE_CATEGORIES: 'api/v1/common/get-employee-leave-categories',
  GET_MY_LEAVES: 'api/v1/common/get-my-leaves',
  REVOKE_LEAVE: 'api/v1/common/revoke-leave',
  SAVE_FCM_TOKEN: 'api/v1/common/save-fcm-token',
  ATTENDANCE_OUT: 'api/v1/common/outTime',
  ATTENDANCE_IN: 'api/v1/common/inTime',
  GET_IN_TIME: 'api/v1/common/getTodayInTime',
  UPDATE_PASSWORD: 'api/v1/common/update-password',
  GET_PROJECTS: 'api/v1/common/get-projects',
  GET_DATA_UPDATION_REQUESTS: 'api/v1/common/get-data-updation-requests',
  CREATE_DATA_UPDATION_REQUEST: 'api/v1/common/create-data-updation-request',
  DELETE_DATA_UPDATION_REQUEST: 'api/v1/common/delete-data-updation-request',
  UPDATE_STATUS_DATA_UPDATION_REQUEST: 'api/v1/common/update-status-data-updation-request',
  LOGOUT: 'api/v1/common/logout',
  GET_COMPENSATORY_LEAVE: 'api/v1/common/get-compensation-days',
  BUSINESS_ENTITIES: 'api/v1/common/business-entities',
  GET_INVOICE_TYPES: 'api/v1/global/invoice-types',
  UPDATE_PHOTO: 'api/v1/common/update-photo',
 
  CHANGE_CSM:'api/v1/common/escl-assign-csm',
  
};
