/************* Imports *************/
import { RoutingModule } from './app-routing.module';
import { AppAutoFocusDirective } from './directive/app-auto-focus.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LightboxModule } from 'ngx-lightbox';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ModalModule} from 'ngx-bootstrap';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// firebase -start
import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import * as firebase from 'firebase';
firebase.initializeApp(environment.firebaseConfig);

// firebase -end

/************* Declarations *************/
import { LoadingComponent } from './publicPages/loading/loading.component';
import { PageNotfoundComponent } from './publicPages/page-notfound/page-notfound.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './publicPages/login/login.component';
import { DashboardComponent } from './services/dashboard/dashboard.component';

/************* Providers *************/
import { AdminService } from './services/admin.service';
import { AuthService } from './services/auth.service';
import { AuthQaService } from './services/auth-qa.service';
import { AuthDevService } from './services/auth-dev.service';
import { AuthLoginService } from './services/auth-login.service';
import { AdminAuthService } from './services/admin-auth.service';
import { InterceptorService } from './services/interceptor.service';
import { NotificationService } from './services/notification.service';
import {environment} from '../environments/environment';
import { AdminLoginComponent } from './publicPages/admin-login/admin-login.component';
import { AuthFinanceService } from './services/auth-finance.service';
import { AuthSalesService } from './services/auth-sales.service';
import { DocumentBlockComponent } from './shared/document-block/document-block.component';
import { VersionCheckService } from './services/version-check.service';
import { ThousandPipe } from './pipe/thousand.pipe';
import { HttpCancelService } from './services/httpcancel.service';
import { ManageHttpInterceptor } from './services/managehttp.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ManagerProjectReportingComponent } from './manager-project-reporting/manager-project-reporting.component';
// import { DatetimePipe } from './pipe/datetime.pipe';
// import { EllipsisPipe } from './pipe/ellipsis.pipe';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    PageNotfoundComponent,
    LoginComponent,
    DashboardComponent,
    AppAutoFocusDirective,
    AdminLoginComponent,
    DocumentBlockComponent,
    ThousandPipe,
    LoaderComponent,
    // ManagerProjectReportingComponent
    // DatetimePipe,
    // EllipsisPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    LightboxModule,
    AngularFontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    // AngularFireStorageModule, // storage
    AngularFireMessagingModule, // messaging,
    ModalModule.forRoot(),
    NgxSkeletonLoaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot(), // ToastrModule added
    // NgbModule
  ],
  providers: [
    AdminService,
    AuthService,
    AuthLoginService,
    AuthDevService,
    AuthQaService,
    AuthFinanceService,
    AuthSalesService,
    AdminAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    NotificationService,
    NgbActiveModal,
    VersionCheckService,
    HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }
  ],
  exports: [
    // EllipsisPipe
  ],
  entryComponents: [
    DocumentBlockComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
