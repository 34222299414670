import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
// import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from '../services/admin.service';
// import { APP } from '../core/globals';
import { CommonApis } from '../core/commonApis';
import { ClientApis } from '../core/clientApis';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  fcmTokens: any;
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private admin: AdminService
  ) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
    this.receiveMessage();
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(token: any) {
    let url = '';
    const postData: any = {
      api_token: localStorage.getItem('api_token'),
      fcm_token: token
    };
    if (localStorage.getItem('desigination') == 'Client') {
      postData['client_id'] = JSON.parse(localStorage.getItem('client_data')).id;
      url = ClientApis.SAVE_FCM_TOKEN;
    } else {
      url = CommonApis.SAVE_FCM_TOKEN;
    }
    this.admin.postData(url, postData).subscribe(
      res => {
        // console.log('FCM Token saved to backend.');
        // this.admin.showAlert('Success', 'fcm token saved.');
      },
      err => {
        // console.log(err);
      }
    );
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        this.fcmTokens = token;
        this.updateToken(token);
        localStorage.setItem('fcm-token', this.fcmTokens);
      },
      err => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
   // console.log('receive Message');
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.currentMessage.next(payload);
      const data = payload.data;
      const notification = payload.notification;
    //  console.log('new message received. ', payload);
      const notificationTitle = data.title ? data.title : notification.title;
      const notificationBody = data.text ? data.text : notification.body;
      if (notificationTitle && notificationBody) {
        // this.toastrService.success('', notificationTitle);
        // this.currentMessage.next(payload);
       // this.admin.showAlert(notificationTitle, notificationBody);
      }
    });
  }
}
