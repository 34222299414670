export const DeveloperApis = {
  GET_EMPLOYEE: 'api/v1/dev/get-all-employees',
  GET_ATTENDANCE_REPORT_EXTRA: 'api/v1/dev/attendance-report-extra',
  GET_DAILY_USER_REPORT:'api/v1/dev/daily-user-report',
  GET_BUG_STATUS: 'api/v1/dev/get-bug-statuses',
  GET_TASK_BUGS: 'api/v1/dev/get-task-bugs',
  GET_EMPLOYEES: 'api/v1/dev/get-employees',
  UPDATE_TASK_BUG_STATUS: 'api/v1/dev/update-task-bug-status',
  GET_BUG_COMMENT: 'api/v1/dev/get-bug-comments',
  ADD_BUG_COMMENT: 'api/v1/dev/add-bug-comment',
  GET_DAILY_ATTENDANCE: 'api/v1/dev/get-daily-attendance',
  CREATE_WORK_HOME_REQUEST: 'api/v1/dev/create-work-home-request',
  CREAT_TASK_COMMENT: 'api/v1/dev/create-task-comment',
  GET_TASK_COMMENTS: 'api/v1/dev/get-task-comments',
  GET_DEVELOPER_ALL_TASKS: 'api/v1/dev/get-developer-all-tasks',
  GET_DEVELOPER_CURRENT_TASK: 'api/v1/dev/get-developer-current-task',
  GET_TASK_STATUS: 'api/v1/dev/get-task-statuses',
  UPDATE_TASK_STATUS: 'api/v1/dev/update-task-status',
  GET_TASK_APPROVED_STATUS: 'api/v1/dev/get-task-approved-status',
  EMPLOYEE_MONTHLY_REPORT: 'api/v1/dev/get-employee-monthly-report',
  GET_EMP_DETAILS: 'api/v1/dev/get-emp-details?',
  PERFORMANCE_REVIEW: 'api/v1/dev/performance-review',
  NOTIFICATIONS: 'api/v1/dev/notifications?',
  UNREAD_NOTIFICATIONS: 'api/v1/dev/unread-notifications',
  KANBAN_TASK_LIST: 'api/v1/dev/kanban-task-list',
  GET_PROJECT_LIST: 'api/v1/dev/get-projects-list',
  EMP_GET_EMPLOYEE: 'api/v1/dev/get-all-employees',
  GET_TECH_STACKS: 'api/v1/dev/get-tech-stacks',
  KANBAN_AllTASK_LIST: 'api/v1/dev/kanban-task-list',
};
