import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthDevService {
  constructor(private router: Router) {}
  canActivate() {
    const token = localStorage.getItem('api_token');
    if (token && localStorage.getItem('desigination') && localStorage.getItem('desigination') == 'Developer') {
      return true;
    }
    if (token && localStorage.getItem('desigination') && localStorage.getItem('desigination') != 'Developer') {
      return false;
    }
    this.router.navigate(['']);
    return false;
  }
}

