import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { url } from './../core/global.model';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { Lightbox } from 'ngx-lightbox';
import { BehaviorSubject } from 'rxjs';
import { APP } from './../core/globals';
import { DeveloperApis } from '../core/developerApis';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private _location: Location,
    private _lightbox: Lightbox
  ) {
    // this.apiEndpoint = API_END_POINT;
    // this.getProject()
  }

  public accessToken: string;
  apiEndpoint = '';
  showLoader = true;
  public reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public priortyArr = ['Low', 'Medium', 'High'];
  public statusArr = ['Open', 'Complete', 'Incomplete', 'R&C', 'Invoice Sent'];
  public ecsalationArr = [
    'Unaddressed',
    'Pending from client',
    'Pending from codebrew',
    'Resolved'
  ];
  public ecsalationType = [
    'Delay in development',
    'Delay in response',
    'Quality Issue',
    'Custom',
    'Wrong Sales Commitment',
    'App Crash',
    "Refund"
  ];
  public bugsStatusArr = ['Open', 'To be tested', 'Closed', 'Invalid'];
  public statusQA = ['Completed', 'Reopen', 'Closed'];
  public dataUpdationTypes = [
    { label: 'Attendance Time Update', required: '(Required info: Date, In Time and Out Time)' },
    { label: 'Forgot to Start Task Timer', required: '(Required info: Date, Project and Task)' },
    { label: 'Milestone Status/Amount Updation', required: '(Required info: Project, Milestone Name and Amount)' },
    { label: 'Other', required: '' }
  ];
  public dataUpdationStatus = ['', 'Pending', 'Completed', 'Rejected'];
  statusArrMile = [
    { label: 'Open', value: 0 },
    { label: 'Complete', value: 1 },
    { label: 'Incomplete', value: 2 },
    { label: 'R&C', value: 3 },
    { label: 'Invoice Sent', value: 4 }
  ];
  public dataUpdationTypesRequiredInfo = (label) => {
    const reqInfo = this.dataUpdationTypes.filter(data => data.label === label);
    if (reqInfo.length) {
      return reqInfo[0].required;
    } else {
      return '';
    }

  }
  public hasData = new BehaviorSubject(false);
  hasDataa = this.hasData.asObservable();
  public checkData = new BehaviorSubject(false);
  checkDataa = this.checkData.asObservable();
  private projectData = new BehaviorSubject<any>([]);
  projectDataa = this.projectData.asObservable();
  private projectChange = new BehaviorSubject<any>('');
  projectChangee = this.projectChange.asObservable();
  public startTimer = new BehaviorSubject(false);
  startTimerr = this.startTimer.asObservable();
  public stopTimer = new BehaviorSubject(false);
  stopTimerr = this.stopTimer.asObservable();
  public closeStopTimer = new BehaviorSubject(false);
  closeStopTimerr = this.closeStopTimer.asObservable();

  public stageData: any;
  public projectDetails: any;
  public projectsData: any;

  public _albums = [];

  // toast(title,text)
  // {
  //   const toast = swal.mixin({
  //     toast: true,
  //     position: 'top-end',
  //     showConfirmButton: false,
  //     timer: 5000,
  //     background: '#1ab394'
  //   });
  //     toast({
  //     type: 'success',
  //     title: title,
  //     text:text
  //   })

  // }

  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();
  keytab(event) {
    const element = event.srcElement.nextElementSibling; // get the sibling element

    if (element == null) {
      // check if its null
      return;
    } else {
      element.focus();
    } // focus if not null
  }
  projectChanged(value) {
    this.projectChange.next(value);
  }
  startTimerOn(value) {
    this.startTimer.next(value);
  }
  stopTimerOff(value) {
    this.stopTimer.next(value);
  }
  closeStopTimerOff(value) {
    this.closeStopTimer.next(value);
  }

  getRunningTask() {
    const data = { api_token: localStorage.getItem('api_token') };

    this.postData(DeveloperApis.GET_DEVELOPER_CURRENT_TASK, data).subscribe(
      (res: any) => {
        const currentRunningTask = res.data;
        // this.editTask = JSON.parse(localStorage.getItem('currentTask'))
        // this.editTask = this.currentRunningTask
        localStorage.setItem('currentTask', JSON.stringify(currentRunningTask));
        this.startTimerOn(true);
      }
    );
  }

  // not in use
  // getProject() {
  //   this.postData(APP.GET_PROJECTS, {
  //     api_token: localStorage.getItem('api_token')
  //   }).subscribe((res: any) => {
  //     this.projectData.next(res.data);
  //   });
  // }
  convertCsv(data, filename) {
    const csvData = this.ConvertToCSV(data);
    const a: any = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;

    const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;

    if (isIE) {
      const retVal = navigator.msSaveBlob(blob, filename + '.csv');
    } else {
      a.download = filename + '.csv';
    }
    // If you will any error in a.download then dont worry about this.
    a.click();
    // this.csvService.download(item, str);
  }

  ConvertToCSV(objArray) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (const index in objArray[0]) {
      // Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    // append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line != '') {
          line += ',';
        }

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  getData(url: string, myParams?: any) {
    const params = myParams ? { params: this.getParams(myParams) } : {};
    return this.http.get<HttpClient>(environment.apiBaseUrl + url, params);
  }


  getModelData(path) {
    // return this.http.get('assets/data/model.json')
    //           .map(response => response.json())
    //           .catch(this.errorHandler);
    return this.http.get<HttpClient>(path);
  }

  putData(url: string, myParams?: any) {
    const params = myParams ? { params: this.getParams(myParams) } : {};
    return this.http.put<HttpClient>(environment.apiBaseUrl + url, params);
  }

  getParams(myParams) {
    let params = new HttpParams();
    for (const key in myParams) {
      params = params.append(key, myParams[key]);
    }
    return params;
  }

  getDataa(url, param) {
    let params = new HttpParams();

    // Begin assigning parameters
    if (param.start_date) {
      params = params.append('start_date', param.start_date);
    }
    if (param.end_date) {
      params = params.append('end_date', param.end_date);
    }
    if (param.employee_id) {
      params = params.append('employee_id', param.employee_id);
    }
    if (param.project_id) {
      params = params.append('project_id', param.project_id);
    }
    params = params.append('graph', param.graph);
    return this.http.get<any>(environment.apiBaseUrl + url, { params });
  }

  postData(url, value) {
    return this.http.post<any>(environment.apiBaseUrl + url, value);
  }
  dateFormat(date) {
    const dateString = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0];
    return dateString;
  }
  dataFor(dateString) {
    const regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

    if (!regex_date.test(dateString)) {
      return false;
    }
    return true;
  }
  checkUrl(url) {
    const validExts = new Array('.png', '.jpg', '.jpeg');
    let fileExt = url;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      return false;
    } else {
      return true;
    }
  }
  timePipe(time) {
    const hr = Math.floor(time / 60);
    const min = time % 60;
    if (hr.toFixed(0) == '0') {
      return min + ' MM';
    } else {
      return hr.toFixed(0) + ' HH ' + min + ' MM';
    }
  }

  timePipe2(time) {
    const hr = Math.floor(time / 60);
    const min = time % 60;
    if (hr.toFixed(0) == '0') {
      return '0:' + min;
    } else {
      return hr.toFixed(0) + ':' + min;
    }
  }

  timePipeForDeficitHours(time) {
    const hr = Math.floor(time / 60);
    const min = time % 60;
    if (hr.toFixed(0) == '0') {
      return min + ' MM';
    } else {
      return hr.toFixed(0) + ' HH ';
    }
  }

  timerPipe(time) {
    const hr = Math.floor(time / 3600);
    // alert((time%3600))
    // alert((time%3600)/60)
    const min = Math.floor((time % 3600) / 60);
    const sec = (time % 3600) % 60;

    return (
      (hr.toFixed(0).toString().length == 1
        ? '0' + hr.toFixed(0)
        : hr.toFixed(0)) +
      ':' +
      (min.toFixed(0).toString().length == 1
        ? '0' + min.toFixed(0)
        : min.toFixed(0)) +
      ':' +
      (sec.toString().length == 1 ? '0' + sec : sec)
    );
  }

  showAlert(title: string, text?: string) {
    Swal({
      title,
      text,
      type: 'success',
      showConfirmButton: false,
      timer: 2500
    });
  }

  warning(title: string, text?: string) {
    Swal({
      title,
      text,
      type: 'warning',
      showConfirmButton: false,
      timer: 2500
    });
  }

  error(title: string, text?: string) {
    Swal({
      title,
      text,
      type: 'error'
    });
  }

  errorAlert(text, status) {
    Swal({
      type: 'error',
      title: 'Oops...',
      text
    }).then(result => {
      if (status) {
        if (localStorage.getItem('currentTask')) {
          this.stopTimerOff(true);
        }
        localStorage.removeItem('api_token');
        localStorage.removeItem('project');
        localStorage.removeItem('desigination');
        localStorage.removeItem('role_type');
        localStorage.removeItem('user_type');
        this.router.navigate(['/login']);
      }
    });
  }

  logout() {
    if (localStorage.getItem('currentTask')) {
      this.stopTimerOff(true);
    }
    const user_type = localStorage.getItem('user_type');
    localStorage.removeItem('api_token');
    localStorage.removeItem('project');
    localStorage.removeItem('desigination');
    localStorage.removeItem('role_type');
    localStorage.removeItem('user_type');

    if (user_type == '0') {
      this.router.navigate(['/admin-login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  getUrl() {
    const len = this._location.path().indexOf(';');
    let str = this._location.path();
    if (len != -1) {
      str = this._location.path().slice(0, len);
    }
    return str;
  }

  loader(value) {
    this.loaderSubject.next(value);
  }

  open(image): void {
    this._albums = [];
    const album = {
      src: image ? image : url.noImage
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, {
      disableScrolling: true,
      centerVertically: true
    });
  }
  close() {
    this._lightbox.close();
  }
  closeSwal() {
    Swal.close();
  }

  /********* Set data in local storage **********/
  setLocalData(key: string, data: any, json?: boolean) {
    localStorage.setItem(key, json ? JSON.stringify(data) : data);
  }

  /********* Get data from local storage **********/
  getLocalData(key: string, json?: boolean) {
    let _data: any = null;
    try {
      _data = json
        ? JSON.parse(localStorage.getItem(key))
        : localStorage.getItem(key);
      return _data;
    } catch (error) {
      if (error instanceof SyntaxError) {
        this.clearLocalData(key);
      }
      return null;
    }
  }

  /********* Remove data from local storage **********/
  clearLocalData(key: string) {
    localStorage.removeItem(key);
  }

  getAccessToken() {
    return localStorage.getItem('api_token');
  }

  getDataForRoyo(token: any, userId: any) {
    const apiBaseUrl = 'https://royochat.code-brew.com/api/v1/users.list?offset=0&count=1000&fields={ \"is_web_access\": 1,\"is_app_access\" : 1}';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-Auth-Token": token, "X-User-Id": userId
      })
    };
    return this.http.get<HttpClient>(apiBaseUrl, httpOptions);
  }

  updateRoyoUser(postData: any, token: any, userId: any) {
    const apiBaseUrl = 'https://royochat.code-brew.com/api/v1/users.setAccess';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-Auth-Token": token, "X-User-Id": userId
      })
    };
    return this.http.post<HttpClient>(apiBaseUrl, postData, httpOptions);
  }

}
