import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { model, DocType } from '../../core/global.model';
import { AdminService } from '../../services/admin.service';
import { BsModalRef } from 'ngx-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { GlobalApis } from 'src/app/core/globalApis';
import { AdminApis } from 'src/app/core/adminApis';
import { ManagerApis } from 'src/app/core/managerApis';

@Component({
  selector: 'app-document-block',
  templateUrl: './document-block.component.html',
  styleUrls: ['./document-block.component.css']
})
export class DocumentBlockComponent implements OnInit {
  @Input('docs') docs: Array<any>;
  @Input('validations') validations: any;
  @Input('subStageId') subStageId: string;
  @Input('projectId') projectId: string;
  @Input('mainIndex') mainIndex: number;
  @Input('name') name: string;
  @Input('stageIndex') stageIndex: number;
  @Input('userType') userType: string;
  @Input('data') data: Array<any>;
  @Output() submitResult = new EventEmitter<boolean>();

  url: string;
  initialState: any;
  docTypes = DocType;
  addedData: Array<any>;
  model: model = {};
  form: FormGroup;
  showError = false;
  bsModalRef: BsModalRef;
  techStack: Array<any>;
  text: string;
  imageTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  constructor(public admin: AdminService, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.addedData = [
      {
        techStackName: '',
        name: '',
        disabled: true
      }
    ];
    this.getTechStacks();
    this.validations.forEach(element => {
      if (element.is_multiple) {
        element.multipleDocs = JSON.parse(JSON.stringify(this.addedData));
      }
    });
  }

  getTechStacks() {
    const data = {
      api_token: localStorage.getItem('api_token')
    };

    this.url =
      this.userType === 'admin'
        ? GlobalApis.GET_TECH_STACKS
        : GlobalApis.GET_TECH_STACKS;
    this.admin.postData(this.url, data).subscribe(res => {
      this.techStack = res.data;
    });
  }

  setStackId(techStackName: string, validationIndex: number, index: number) {
    this.validations[validationIndex].multipleDocs[
      index
    ].techStackName = techStackName;
  }

  addNewStack(validationIndex: number, index: number) {
    // this.addedData.push({techStackName: '', name: ''});
    if (
      this.validations[validationIndex].multipleDocs[index].techStackName &&
      this.validations[validationIndex].multipleDocs[index].name
    ) {
      this.validations[validationIndex].multipleDocs[index].disabled = false;
      const md = JSON.parse(
        JSON.stringify(this.validations[validationIndex].multipleDocs)
      );
      md.push({ techStackName: '', name: '', disabled: true });
      this.validations[validationIndex].multipleDocs = md;
    } else {
      this.admin.showAlert('ERROR', 'Fill previous row first!');
      return false;
    }
  }

  removeStack(validationIndex: number, index: number) {
    if (this.validations[validationIndex].multipleDocs.length > 1) {
      // this.addedData.splice(index, 1);
      this.validations[validationIndex].multipleDocs.splice(index, 1);
    }
  }

  onSubmit() {
    for (let index = 0; index < this.validations.length; index++) {
      const element = this.validations[index];
      if (element.is_multiple) {
        const d = [];
        for (let i = 0; i < element.multipleDocs.length; i++) {
          const ele = element.multipleDocs[i];
          // checking if field empty
          if (
            !ele ||
            !ele.techStackName ||
            ele.techStackName.length === 0 ||
            !ele.name ||
            ele.name.length === 0
          ) {
            Swal('Error', 'Fill all fields', 'error');
            return;
          }
          d.push(ele);
        }
        element.doc = JSON.stringify(d);
      } else {
        // checking if field empty
        if (!element.doc || element.doc.length === 0) {
          Swal('Error', 'Fill all fields', 'error');
          return;
        }
        element.doc = JSON.stringify([
          { techStackName: '', name: element.doc, disabled: false }
        ]);
      }
    }
    this.activeModal.close('success');
    const data = {
      api_token: localStorage.getItem('api_token'),
      project_id: this.projectId,
      sub_stage_id: this.subStageId,
      validation: this.validations,
      is_skipped: false
    };
    this.url =
      this.userType === 'admin'
        ? AdminApis.ADMIN_MOVE_TO_NEXT_STAGE
        : ManagerApis.EMP_MOVE_TO_NEXT_STAGE;
    this.admin.postData(this.url, data).subscribe(
      res => {
        this.showError = false;
        res.data.current_log.docs = JSON.parse(res.data.current_log.docs);
        res.data.current_log.docs.forEach(element => {
          element.doc = JSON.parse(element.doc);
        });
        this.admin.stageData[this.mainIndex].sub_stages[
          this.stageIndex
        ].sub_stage_log[0] = res.data.current_log;
        this.admin.stageData.forEach(element => {
          element.sub_stages.forEach(ele => {
            if (
              ele.id === res.data.next_stage_id &&
              res.data.next_stage_detail != null
            ) {
              element.isActive = 1;
              const ssl = {
                status: res.data.next_stage_detail.sub_stage_log[0].status,
                start_date: res.data.current_log.end_date,
                end_date: res.data.current_log.end_date,
                stage_taken_days:
                  res.data.next_stage_detail.sub_stage_log[0].stage_taken_days,
                is_skipped:
                  res.data.next_stage_detail.sub_stage_log[0].is_skipped
              };
              // ele = res.data.next_stage_detail;
              ele.deficit_percent = res.data.next_stage_detail.deficit_percent;
              ele.validate_on = res.data.next_stage_detail.validate_on;
              ele.total_work_minutes =
                res.data.next_stage_detail.total_work_minutes;
              ele.max_task_hours = res.data.next_stage_detail.max_task_hours;
              ele.sub_stage_log = [ssl];
            }
          });
        });
      },
      () => {
        this.activeModal.close('close');
      }
    );
  }

  onFileUpload(event: any, index) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.imageTypes.includes(file.type)) {
        if (file.size / 5000000 > 100) {
          event.target.value = '';
          this.admin.errorAlert(
            'File size must not be greater than 5MB',
            false
          );
          return;
        }
      } else {
        event.target.value = '';
        this.admin.errorAlert(
          'Invalid file type. Valid file types: JPG, PNG, PDF, DOC',
          false
        );
        return;
      }

      this.model.loading = true;
      const fd = new FormData();
      fd.append('api_token', localStorage.getItem('api_token'));
      fd.append('attachment', file);
      this.admin.postData(GlobalApis.UPLOAD_ATTACHMENT, fd).subscribe(res => {
        this.validations[index].doc = res.data;
        this.model.loading = false;
      });
    }
  }
}
